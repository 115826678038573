import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Ratio, Image } from 'react-bootstrap';
import logo from './images/logo_HealthSquad_complet.png';
import {Translations} from '../src/constants/i18n';

const Nous = ({translation}) => {


    const [Mail, setMail] = useState('');
    const URL = 'https://www.useradventure.net/'
    console.log(Mail)

    const t = (value) => {
      return Translations[0][translation][value];
     }

    useEffect(()=>{
      window.scrollTo(0, 0)
      }, [])

const valider = () => {
  var position = Mail.indexOf("@");
  var reste = Mail.substr(-position);
  var posit = reste.indexOf(".");
  //alert(posit)
  if(position !== -1 && posit !== -1 ){
    fetch(`${URL}json_get_react_register_newsletter.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({mail:Mail})
    })
      .then((response) => response.json())
      .then(() => {alert('Merci pour ton inscription à la newsletter !')
      setMail('')})
      .catch((error) => console.error(error))
}else{
  alert('Email non valide')

}}
   

return (
       
       
  <Container style={{paddingTop:180, paddingBottom:180, backgroundColor:'#f5f7fa'}} fluid>  
  <Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>{t("aboutO")}</Col>
  <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}><span className="gradient-text">{t("about1")}</span></Col>

    
    
    
    <Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{color:'#4f6382', fontSize:22,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif',paddingInline:30,marginTop:60}}>{t("about2")}</Col>

<Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{color:'#4f6382', fontSize:22,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif',paddingInline:30,marginTop:60}}>{t("about3")}</Col>
  
<Image src={logo} style={{marginTop:60,paddingInline:30}} fluid alt="logo_HealthSquad"/>
    
<Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{color:'#4f6382', fontSize:22,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif',paddingInline:30,marginTop:60}}>{t("about4")}</Col>


<Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{color:'#4f6382', fontSize:22,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif',paddingInline:30,marginTop:60}}>{t("about5")}</Col>
    
    
<Col lg={{offset:1,span:10}} className="text-center text-lg-start" style={{color:'#4f6382', fontSize:22,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif',paddingInline:30,marginTop:60}}>
    <div className="videop"   >
                <Ratio aspectRatio="16x9">
                    <iframe  style={{ cursor:'pointer'}}  title="animation HealthSquad"  className="rounded" src="https://www.youtube.com/embed/fm9grPat8QY?rel=0&loop=1&autoplay=0" frameborder= "0" allow="autoplay" ></iframe>
                </Ratio>
            </div>
            </Col>
  </Container>

    )
}

export default Nous
