import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import Nous from './Nous';
import Contact from './Contact';
import Inscription from './Inscription';
import Mention from './Mention';
import Blog from './Blog';
import Post from './Post';
import Loi from './Loi';
import Suivi from './Suivi';
import Devis from './Devis';
import Recrutement from './Recrutement';
import Services from './Services';
import PPDP from './PPDP';
import Saas from './Saas';
import Menu from './components/Menu'
import Footer from './components/Footer';
import u75 from './images/logo_HealthSquad_complet.png';

function App() {


  const [lang, setLang] = useState("fr");

  const onChangeLang = (e) => {

    const lang_code = e.target.value;
    if(lang_code ===  "fr"){
      console.log('lang_code',lang_code)
      setLang(lang_code)
     }
  else if(lang_code ===  "en"){
    console.log('lang_code',lang_code)
     setLang(lang_code)
    }

  }


  return (
    <Suspense fallback='loading'>
    <BrowserRouter>
       <Menu ChangeLang={(data)=>onChangeLang(data)} translation={lang}/>
      <Routes>
        <Route path="/" element={<Home translation={lang}/>} />
        <Route path="/blog" element={<Blog translation={lang}/>} />
        <Route path="/saas" element={<Saas translation={lang}/>} />
        <Route path="/post/:id/:titre" element={<Post translation={lang}/>}/>
        <Route path="/inscription" element={<Inscription translation={lang}/>} />
        <Route path="/contact" element={<Contact translation={lang}/>} />
        <Route path="/about" element={<Nous translation={lang}/>} />
        <Route path="/services" element={<Services translation={lang}/>}/>  
        <Route path="/services/recrutement" element={<Recrutement translation={lang}/>}/>  
        <Route path="/services/Loi-anti-cadeau" element={<Loi translation={lang}/>}/> 
        <Route path="/services/Suivi-post-production" element={<Suivi translation={lang}/>}/>  
        <Route path="/devis" element={<Devis translation={lang}/>}/>
        <Route path="/CGU" element={<Mention translation={lang}/>}/>
        <Route path="/protection-des-donnees-personnelles" element={<PPDP translation={lang}/>}/>
      </Routes>
      <Footer translation={lang}/>
    </BrowserRouter>
      </Suspense>

    
  );
}

export default App;
