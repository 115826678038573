import React, {useState, useMemo, useEffect} from 'react';
import { Container, Card, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Form, Alert } from 'react-bootstrap';
import {useWindowDimensions} from 'react-native';
import {NavLink} from "react-router-dom";
import mockup_pc from './images/mockup3.png';
import test1 from './images/Test1.png'; 
import approuve from './images/approuve.png';
import {Translations} from '../src/constants/i18n';
import convention from './images/convention.png';
import suivi from './images/suivi-post.png';

const Services = ({translation}) => {


  const { height, width } = useWindowDimensions();
 
    const URL = 'https://www.useradventure.net/'
    const [step, setStep] = useState(1);

  
    const t = (value) => {
      return Translations[0][translation][value];
     }
  
    useEffect(()=>{
      window.scrollTo(0, 0)
      }, [])

   

    return (
        
        <>
    <Container style={{paddingTop:100, paddingBottom:180, backgroundColor:'#f5f7fa'}} fluid>  
      
     
      <Row style={{paddingBottom: 40}}>
      <Col   lg={{span: 4, offset: 4 }} style={{  paddingBottom: 60}}>
        <Col className="text-center" style={{margin:60, marginTop:100 ,fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>SERVICES</Col>
        <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'600', fontFamily:'Space Grotesk, sans-serif'}}>{t("service0")} <span className="gradient-text2">{t("service0-1")} </span>{t("service0-2")}</Col>
      </Col>  
      <Col  md={{ span: 10, offset: 1 }}>
       { width > 1000 && <>
        <Row  className="justify-content-md-center"  style={{ paddingBlock:10}}>
           <Col className="joinBtn" onClick={()=> setStep(1)} lg="3" style={{cursor:'pointer', background: step === 1 ? '#ffffff': undefined, boxShadow: step === 1 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              01
              </Col>  
              <Col xs={9} style={{color: '#60697b', fontSize:17, fontWeight: 600}}  className="my-auto">
              {t("service1-1")}
              </Col> 
              </Row>
            </Col>  
            <Col className="joinBtn" onClick={()=> setStep(2)} lg="3" style={{cursor:'pointer', background: step === 2 ? '#ffffff': undefined, boxShadow: step === 2 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              02
              </Col>  
              <Col xs={9} style={{color: '#60697b', fontSize:17, fontWeight: 600}} className="my-auto">
              {t("service2-1")}
              </Col> 
              </Row>
            </Col>  
            <Col className="joinBtn" onClick={()=> setStep(3)} lg="3" style={{cursor:'pointer', background: step === 3 ? '#ffffff': undefined, boxShadow: step === 3 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              03
              </Col>  
              <Col xs={9}  style={{color: '#60697b', fontSize:17, fontWeight: 600}} className="my-auto">
              {t("service3-1")}
              </Col> 
              </Row>
            </Col>  
          
            </Row></>}
            
            {(step === 1 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'first' }} lg={{ order: 'first', span:5 }}>

        <Image src={test1} style={{borderRadius:10}} fluid />
        </Col>
        <Col  xs={{ order: 'last' }} lg={{ order: 'last' , span:5}} style={{paddingInline:30, paddingBlock:30}}>
        
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service1-2")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service1-3")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service1-4")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service1-5")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service1-6")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service1-7")}</Col>
             
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  
    <Col style={{marginTop:0}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/recrutement'>En savoir plus</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        
  </Row>}
     {(step === 2 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'last' }} lg={{ order: 'first', span:5 }} style={{paddingInline:30, paddingBlock:30}}>
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service2-2")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service2-3")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service2-4")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service2-5")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service2-6")}</Col>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
    <Col style={{marginTop:0}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/Loi-anti-cadeau'>En savoir plus</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        <Col  xs={{ order: 'first' }} lg={{ order: 'last' , span:5}}  >

        <Image src={convention} style={{borderRadius:10}} fluid />
        </Col>
  </Row>}

  {(step === 3 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'first' }} lg={{ order: 'first', span:5 }}  >
        <Image src={suivi} style={{borderRadius:10}} fluid />
        </Col>
        <Col  xs={{ order: 'last' }} lg={{ order: 'last' , span:5}} style={{paddingInline:30, paddingBlock:30}}>
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
                <Col  style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service3-2")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service3-3")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service3-4")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service3-5")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid />{t("service3-6")}</Col>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
    <Col style={{marginTop:10}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/suivi-post-production'>En savoir plus</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        
  </Row>}
  </Col>
        </Row>  
 
    </Container>
     </>
    )
}

export default Services

