import React, {useState, useEffect} from 'react';
import { BsTelephone } from "react-icons/bs";
import { FiMail, FiMapPin } from "react-icons/fi";
import { Container, Row, Col, Button } from 'react-bootstrap';
import {Translations} from '../src/constants/i18n';
import ModalFin from './components/ModalFin';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");


const Contact = ({translation}) => {

  const URL = 'https://www.useradventure.net/'
  const [Nom, setNom] = useState('');
  const [Mail, setMail] = useState('');
  const [msg, setMsg] = useState('');
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const contact1 = 'maxime.aldegheri@useradventure.fr'
  const contact2 = 'maxime.aldegheri@healthsquad.fr'
  const [showModal, setshowModal] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0)
    }, [])


    const t = (value) => {
      return Translations[0][translation][value];
     }
  

  const ChangeNom = (event) => {
      setNom(event)
      if(event !== '' && Mail !=='' && msg !==''){
        setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };
    
    

    const ChangeMail = (event) => {
      setMail(event)
      if(event !== '' && Nom !=='' && msg !==''){
        setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };

  

    const ChangeMsg = (event) => {
      setMsg(event)
      if(event !== '' && Nom !=='' && Mail !==''){
        setdisabledcolor(`linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`)
        setdisabled(true)
      } else {
        setdisabledcolor('#DCDCDC')
        setdisabled(false)
      }
     console.log(event)
    };


    const run = async (message) => {
      const response = await mailchimp.messages.sendTemplate({
        template_name: "HSContact",
        "template_content": [{}],
        message: message,
      });
      console.log(response);
      setNom('')
      setMsg('')
      setMail('')
      setshowModal(true)
      setdisabledcolor('#DCDCDC')
      setdisabled(false)
    };

    const validCommand1 = () => {
      var position = Mail.indexOf("@");
      var reste = Mail.substr(-position);
      var posit = reste.indexOf(".");
      //alert(posit + position + Mail )
      if(position !== -1 && posit !== -1 ){
      if(disabled){
        const message = {
          from_name: "HealthSquadContact",
          from_email: "contact@healthsquad.fr",
          subject: `Contact HealthSquad`,
          to: [
            {
              email: contact1,
              type: "to"
            },
            {
              email: contact2,
              type: "cc"
            }
          ],
          "merge_vars": [
            {
                "rcpt": contact1,
                "vars": [
                  {
                    "name": "EMAILPRO",
                    "content": Mail
                  },
                  {
                    "name": "TYPE",
                    "content": "Contact"
                  },
                  {
                    "name": "MOD",
                    "content": msg
                  },
                  {
                    "name": "NOM",
                    "content": Nom
                  },
                ]
            },
            {
              "rcpt": contact2,
              "vars": [
                {
                  "name": "EMAILPRO",
                  "content": Mail
                },
                {
                  "name": "TYPE",
                  "content": "Contact"
                },
                {
                  "name": "MOD",
                  "content": msg
                },
                {
                  "name": "NOM",
                  "content": Nom
                },
              ]
          }
        ]
        }
      run(message) 
        }else{
          alert('Veuillez remplir tous les champs, Merci!')
        }
        }else{
      alert('Email non valide')
    }
  }

  return (
      <div>
      <Container fluid style={{background: '#f0f0f8', paddingBottom : 120 ,paddingTop:200}}>

      <Row style={{justifyContent:'center',alignItems:'flex-start', background: '#f0f0f8'}}>
      
      <Col lg={{  span:6 }} style={{paddingInline:30, paddingBlock:0}}>
      
            <Col lg={{span: 10, offset: 1 }} xxl={{span: 8, offset: 2 }}  style={{backgroundColor:''}}>
            
              <Col className="text-lg-center text-center gradient-text2"  style={{color:'black', fontSize:56,fontWeight: '600' ,marginBottom:10,lineHeight:1.2}}>{t("contactO")}</Col>
              <Col className="text-center" style={{marginTop:10,marginBottom:40,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{t("contact1")}</Col>
              <Col  style={{color:'#4d7abe', fontSize:17,fontFamily:'Space Grotesk, sans-serif',fontWeight: '600' ,marginBottom:20,lineHeight:1.4}}><FiMail  style={{color:'#d874a6', fontSize:26,marginRight:10}}/><a style={{fontSize: 17,borderWidth:0, backgroundColor:'transparent', color:'#4d7abe'}} href='mailto:contact@healthsquad.fr'>contact@healthsquad.fr</a></Col>
              <Col  style={{color:'#4d7abe', fontSize:17, fontFamily:'Space Grotesk, sans-serif' ,fontWeight: '600',marginBottom:20,lineHeight:1.4}}><BsTelephone style={{color:'#d874a6', fontSize:26,marginRight:10}}/>07 69 11 46 02</Col>
               <Col  style={{color:'#4d7abe', fontSize:17, fontFamily:'Space Grotesk, sans-serif',fontWeight: '600' ,marginBottom:20,lineHeight:1.4}}><span><FiMapPin style={{color:'#d874a6', fontSize:26,marginRight:10}}/>PARC EURASANTE<br/>
               <FiMapPin style={{color:'transparent', fontSize:26,marginRight:10}}/>260 rue Salvador Allende<br/>
               <FiMapPin style={{color:'transparent', fontSize:26,marginRight:10}}/>Bâtiment B<br/>
               <FiMapPin style={{color:'transparent', fontSize:26,marginRight:10}}/>59120 LOOS<br/>
               <FiMapPin style={{color:'transparent', fontSize:26,marginRight:10}}/>FRANCE</span></Col>
           
            </Col>
           
            
      </Col>
      
      <Col lg={{  span:6}} style={{paddingInline:30, paddingBlock:0}} >
      <div className="form-floating mb-3">
                              <input type="Nom" value={Nom} onChange={e =>ChangeNom(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingInput" placeholder="name@example.com"/>
                              <label>{t("contact2")}</label>
                          </div>
                         
                          <div className="form-floating mb-3">
                              <input type="Prénom" value={Mail} onChange={e =>ChangeMail(e.target.value)} style={{borderRadius:10}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                              <label>{t("contact3")}</label>
                          </div>
                          
                         
                          <div className="form-floating mb-3">
                              <textarea  rows="5" type="Prénom" value={msg} onChange={e =>ChangeMsg(e.target.value)} style={{borderRadius:10, height:150}} className="form-control" id="floatingPrenom" placeholder="floatingPrenom"/>
                              <label>{t("contact4")}</label>
                          </div>  
  <div className="d-grid gap-2">
  <Button variant="outline-light" onClick={()=> validCommand1()}  style={{borderWidth:0, background: disabledcolor, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}>{t("contact5")}</Button>
  </div>
      </Col>
</Row>
     <ModalFin show={showModal} Quit={() => {setshowModal(false)}} />
      </Container>
     
      </div>
  )
}

export default Contact