import React, {useState} from 'react';
import logo from '../images/logo_HealthSquad_complet.png';
import { Image, Button  } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import $ from 'jquery';
import { NavLink, useLocation } from 'react-router-dom';
import { LANGUAGES } from '../constants/index'  
import {Translations} from '../constants/i18n';
import {useWindowDimensions} from 'react-native';

const isActive = ({ isActive }) => `link ${isActive ? "active" : ""}`;


const Menu = ({ translation, ChangeLang}) => {

  const [lang, setLang] = useState(window.navigator.language.substr(0, 2) === 'fr' ? 'fr' : 'en');
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const { height, width } = useWindowDimensions();
  var largeur = window.innerWidth;
  let history = useLocation();
  
 console.log('history',history.pathname)
 
  const t = (value) => {
    return Translations[0][translation][value];
   }

const closeToggle = () =>{
  if (largeur < 1400 ){
  var btn = document.getElementsByClassName("navbar-toggler");
btn[0].click();
  }
}

  return (

    <Navbar  fixed="top" expand="xl" className="bg-body-tertiary justify-content-between"  style={{backgroundColor:'white'}}>
    <Container>
      <Navbar.Brand href="/"><Image src={logo} style={{height: width < 768 ? 65 : 80}} fluid alt="logo_HealthSquad"/></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto my-2 my-lg-2"  navbarScroll>
        <NavLink onClick={()=>closeToggle()} className={isActive} style={{paddingBlock:10, paddingRight:20}} to='/'>{t('home')}</NavLink>
        <NavDropdown renderMenuOnMount={true} style={{paddingBlock:2, paddingRight:20}} title={<NavLink onClick={()=>closeToggle()} className={isActive} to='/services'>{t('services')}</NavLink>} id="navbarScrollingDropdown">
            <NavDropdown.Item href='/services/recrutement'><NavLink onClick={()=>closeToggle()} className={isActive} to='/services/recrutement'>{t('recrutement')}</NavLink></NavDropdown.Item>
            <NavDropdown.Item href='/services/Loi-anti-cadeau'><NavLink onClick={()=>closeToggle()} className={isActive} to='/services/Loi-anti-cadeau'>{t('loi')}</NavLink></NavDropdown.Item>
            <NavDropdown.Item href='/services/suivi-post-production'><NavLink onClick={()=>closeToggle()} className={isActive} to='/services/suivi-post-production'>{t('suivi')}</NavLink></NavDropdown.Item>
        </NavDropdown>
        <NavLink onClick={()=>closeToggle()} style={{paddingBlock:10, paddingRight:20}} className={isActive} to='/saas'>{t('saas')}</NavLink>
        <NavLink onClick={()=>closeToggle()} style={{paddingBlock:10, paddingRight:20}} className={isActive} to='/blog'>{t('blog')}</NavLink>
        <NavLink onClick={()=>closeToggle()} style={{paddingBlock:10, paddingRight:20}} className={isActive} to='/inscription'>{t('inscription')}</NavLink>
        <NavLink onClick={()=>closeToggle()} style={{paddingBlock:10, paddingRight:20}} className={isActive} to='/about'>{t('about')}</NavLink>
        <NavLink onClick={()=>closeToggle()} style={{paddingBlock:10, paddingRight:20}} className={isActive} to='/contact'>{t('contact')}</NavLink>
    </Nav>
          <Nav style={{ paddingRight:20}}>
          <Button  variant="outline-light"  style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:20, paddingBlock:15, fontSize:17,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:0, color:'white', textDecorationLine:'inherit'}}  to='/devis'>{t("btn1")}</NavLink></Button>
         
           </Nav>  
      <select defaultValue={lang} onChange={ChangeLang}  >
                {
                    LANGUAGES.map(({ code, label }) => (
                        <option
                            key={code}
                            value={code}
                        >{label}</option>
                    ))
                }
            </select>
      </Navbar.Collapse>
      </Container>
    </Navbar>
    
  )
}

export default Menu


