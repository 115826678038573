import React, {useState, useMemo, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Container, Card, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Form, Alert } from 'react-bootstrap';
import {Linking, StyleSheet, View, FlatList, Text, TextInput, TouchableOpacity, StatusBar, Dimensions, ScrollView, ActivityIndicator, Platform, ImageBackground, useWindowDimensions} from 'react-native';
import Figure from 'react-bootstrap/Figure';
import {Translations} from '../src/constants/i18n';

const Blog = ({translation}) => {


  
  const { height, width } = useWindowDimensions();
  const largeur = window.innerWidth;
    const color1 = "#007F8B";
    const color2 = "#988EFA";
    const URL = 'https://www.useradventure.net/'
    const [Nom, setNom] = useState('');
    const [Prenom, setPrenom] = useState('');
    const [Mail, setMail] = useState('');
    const [DataEtude, setDataEtude] = useState([]);

    const t = (value) => {
      return Translations[0][translation][value];
     }
  
    useEffect(()=>{
      window.scrollTo(0, 0)
      fetch('https://www.useradventure.net/json_get_react_blog.php')
      .then((response) => response.json())
      .then((response) => { 
    setDataEtude(response)
    console.log(response)
      })
      .catch((error) => alert(error))
      
      }, [])

     

   

    return (
        
      <>
       
      <Container style={{paddingTop:140, paddingBottom:80, backgroundColor:'#f5f7fa'}} fluid>  

      <Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>BLOG</Col>
      <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("blogHome1")} <span className="gradient-text">{t("blogHome2")}</span></Col>
      <Col className="text-center" style={{marginTop:20,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{t("blog0")}</Col>
    
<Row xs={1} md={2} style={{marginTop:80, padding:20}}>
{ DataEtude && DataEtude.map((item, index) => (<div key={index}>
  <Col  style={{ padding:20}}>
   <Link to={"/post/"+ item.ID + "/"+ (item.Titre.replaceAll(" ", "-")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase('tr')}>
   <Col >
   <div className="join1Btn1" style={{ height: width < 768 ? width/2 : width/3.4,
      width: '100%',
      backgroundImage: `url(${item.Img})`,
      backgroundSize: '100% 100%' ,
      borderRadius:10,
      objectFit:'cover',
     
    }}>
     
    </div>
   </Col></Link>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{item.Titre}</Col>
   <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 16:14,marginBlock:10,color: '#60697b', fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{item.Text1}</Col>
   <Link to={"/post/"+ item.ID + "/"+ (item.Titre.replaceAll(" ", "-")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase('tr')} style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>Consulter l'article</Link>
   </Col>
   </div>
 ))} 
</Row>

</Container>
     </>
    )
}

export default Blog

