import React, {useState, useMemo, useEffect} from 'react';
import { Container, Card, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, InputGroup, FormControl, Button, Form, Alert } from 'react-bootstrap';
import {useWindowDimensions} from 'react-native';
import {NavLink} from "react-router-dom";
import mockup_pc from './images/mockup3.png';
import test1 from './images/Test1.png'; 
import approuve from './images/approuve.png';
import {Translations} from '../src/constants/i18n';


const Recrutement = ({translation}) => {


  const { height, width } = useWindowDimensions();
 
    const URL = 'https://www.useradventure.net/'
  

  
    const t = (value) => {
      return Translations[0][translation][value];
     }
  
    useEffect(()=>{
      window.scrollTo(0, 0)
      }, [])

   

    return (
        
        <>
    <Container style={{paddingTop:180, paddingBottom:100, backgroundColor:'#f5f7fa'}} fluid>  
      <Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>Services</Col>
      <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}><span className="gradient-text">{t("recrutement0")}</span></Col>
      <Col  xxl={{ span: 12, offset: 0 }}>
          <Row  style={{justifyContent:'center',alignItems:'center', padding:40, paddingBottom:0}}>
        <Col  xs={{ order: 'first' }} xxl={{ order: 'first', span:6 }}>

        <Image src={test1} style={{borderRadius:10}} fluid />
        </Col>
        <Col  xs={{ order: 'last' }} xxl={{ order: 'last' , span:6}} style={{paddingInline:30, paddingBlock:10}}>
        
              <Col xxl={{span: 12, offset: 0 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("recrutement1")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.6}}>{t("recrutement2")}</Col>
<Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.6}}>{t("recrutement3")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.6}}>{t("recrutement4")}</Col>
                <Col  style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.6}}>{t("recrutement5")}</Col>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  </Row>
              </Col>  
        </Col>   
  </Row>
  <div className="d-grid gap-2" style={{ padding:40}}>
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/Devis'>{t("btn1")}</NavLink></Button>
    </div> 
  </Col>
    </Container>
     </>
    )
}

export default Recrutement

