import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup  } from 'react-bootstrap';


const Mention = () => {

    useEffect(()=>{
      window.scrollTo(0, 0)
      }, [])

    return (
        <div>
        <Container style={{paddingBlock:150,background: '#f0f0f8'}} fluid>
        <Col md={{ span: 10, offset:1  }} lg={{ span: 6, offset: 3 }} >
       

      <Col className="text-center" style={{fontSize: 34,color: '#4d7abe',paddingInline:30, fontWeight:'bold', marginBottom:50, fontFamily:'Space Grotesk, sans-serif' }}>Mention légales et CGU</Col>
      <Col className="text-start" style={{fontSize: 22,paddingInline:30, marginTop:20, fontWeight:'bold'}}>Le propriétaire et/ou éditeur est une personne morale.</Col>
      <Col className="text-start" style={{paddingInline:30 }}><span style={{ fontWeight:'bold' , fontStyle:'italic'}}>SYMPLICITY</span> : SAS au capital de 24 000 Euros • Siren 900990862 au R.C.S. de Lille</Col>< br/>
      <Col className="text-start" style={{paddingInline:30 }}><span style={{ fontWeight:'bold' , fontStyle:'italic'}}>Directeur de la publication</span> : Mr Julien Aldegheri • HEALTH SQUAD • 15 rue Auguste potié • 59320 Emmerin</Col>< br/>
      <Col className="text-start" style={{paddingInline:30 }}><span style={{ fontWeight:'bold' , fontStyle:'italic'}}>Conception</span> : Maxime Aldegheri • HEALTH SQUAD • 15 rue Auguste potié • 59320 Emmerin</Col>< br/>
      <Col className="text-start" style={{paddingInline:30 }}><span style={{ fontWeight:'bold' , fontStyle:'italic'}}>Hébergeur</span> : OVH – Siège Social 2 rue Kellermann – 59100 ROUBAIX – Tél : 08 203 203 63 / n° indigo (0,118 €/min)</Col>
      <Col className="text-start" style={{paddingInline:30 }}>Les informations fournies sur <a href="https://www.healthsquad.fr">www.healthsquad.fr</a> le sont à titre informatif.</Col>< br/>
      <Col className="text-start" style={{paddingInline:30}}>Symplicity ne saurait garantir l’exactitude, la complétude, l’actualité des informations diffusées sur le site. Les informations de ce site peuvent contenir des inexactitudes techniques ou des erreurs typographiques. Ces informations peuvent être périodiquement modifiées et les modifications seront incorporées dans les nouvelles éditions. Symplicity peut, à tout moment, sans préavis, apporter des améliorations ou des changements aux programmes ou aux services décrits sur ce site. Toute reproduction, représentation, modification, publication, transmission, dénaturation, totale ou partielle du site ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit est interdite. Toute exploitation non autorisée du site ou de son contenu, des informations qui y sont divulguées engagerait la responsabilité de l’utilisateur et constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la Propriété Intellectuelle.</Col>< br/>
      
      <Col className="text-start" style={{fontSize: 22,paddingInline:30, marginTop:20, fontWeight:'bold'}}>Droits d’auteur et Marques</Col>
      <p className="text-start" style={{paddingInline:30}}>La totalité des éléments du site <a href="https://www.healthsquad.fr">www.healthsquad.fr</a>, notamment les textes, présentations, illustrations, photographies, arborescences et mises en forme sont, sauf documents publics et précisions complémentaires, la propriété intellectuelle exclusive de la société SYMPLICITY ou de ses partenaires.</p>
      <p className="text-start" style={{paddingInline:30 }}>A ce titre, leurs représentations, reproductions, imbrications, diffusions et rediffusions, partielles ou totales, sont interdites conformément aux dispositions du Code de la propriété intellectuelle. Toute personne y procédant sans pouvoir justifier d’une autorisation préalable et expresse du détenteur de ces droits encourt les peines relatives au délit de contrefaçon prévues au Code de la propriété intellectuelle.</p>
      <p className="text-start" style={{paddingInline:30 }}>En outre, les représentations, reproductions, imbrications, diffusions et rediffusions, partielles ou totales, de la base de données contenue dans le site <a href="https://www.healthsquad.fr">www.healthsquad.fr</a> sont interdites en vertu des dispositions du Code de la propriété intellectuelle.</p>
      <p className="text-start" style={{paddingInline:30}}>En tout état de cause, sur toute copie autorisée de tout ou partie du contenu du site, devra figurer la mention « Copyright 2021 UserAdventure tous droits réservés ».</p>
     
      
      <Col className="text-start" style={{fontSize: 22,paddingInline:30, marginBlock:20, fontWeight:'bold'}}>Protection des Données Personnelles</Col>
  <Col className="text-start" style={{paddingInline:30}}>Nous pouvons être amenés à collecter et traiter certaines données personnelles. Vous pourrez prendre connaissance de notre politique de protection des données personnelles sur notre site 
                                        <a href="/PPDP"> Healthsquad.fr </a>, sur nos points de relation client 
                                        <a href="/contact"> Contact </a>
                                        ou par mail à l’adresse <a href='mailto:contact@healthsquad.fr'>contact@healthsquad.fr</a>.</Col>
      
      </Col>
    </Container>
        
       
        
        </div>
    )
}

export default Mention
