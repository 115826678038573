import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {useWindowDimensions,} from 'react-native';
import mockup_pc1 from './images/MockupSaaS1.png';
import mockup_pc2 from './images/MockupSaaS2.png';
import mockup_pc3 from './images/mockup3.png';
import doodle1 from './images/doodle1.svg';
import doodle2 from './images/doodle2.svg';
import {Translations} from '../src/constants/i18n';

const Saas = ({translation}) => {


  const { height, width } = useWindowDimensions();
 
    const URL = 'https://www.useradventure.net/'
  
    const t = (value) => {
      return Translations[0][translation][value];
     }
  
    useEffect(()=>{
      window.scrollTo(0, 0)
      }, [])


    return (
        
        <>
       
      <Container style={{paddingTop:160, paddingBottom:180, backgroundColor:'#f0f0f8'}} fluid>  
      <Col style={{background: '#f0f0f8'}}>
      <Col className="text-center" style={{paddingTop:20, fontSize: 24,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>{t("saas0")}</Col>
      <Col className="text-center"  style={{marginTop:20,fontSize: 54,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}><span className="gradient-text">HealthSquad Manager</span></Col>
      <Col className="text-center" style={{marginTop:20, paddingBottom:90,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{t("saas1")}</Col>
     </Col>
      <Row style={{justifyContent:'center',alignItems:'center'}}>
        
        <Col xs={{ order: 'last' }} lg={{ order: 'first', span:6 }} style={{paddingInline:30, paddingBlock:30}}>
        
              <Col lg={{span: 10, offset: 1 }} xxl={{span: 7, offset: 2 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  className="text-start" style={{backgroundColor:''}}>
               {/* <Image src={doodle1} style={{ marginLeft: width < 768 ? 0 : -50,height:50}} /> */}
               </Col>
                <h1  style={{color:'black', fontSize:56,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("saas2")} <span className="gradient-text2">{t("saas3")}</span></h1>
                <h3  style={{color:'#4f6382', fontSize:25,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.4}}>{t("saas4")}</h3>
             
             
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  
    <Col style={{marginTop:0}}>
   
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/Devis'>{t("btn1")}</NavLink></Button>
   
          </Col>  
  </Row>
 
              </Col>
              <Col  className="text-end" style={{backgroundColor:''}}>
              <div class="masked1"></div>
               </Col>
               
        </Col>
        
        <Col xs={{ order: 'first' }} lg={{ order: 'last' , span:6}}  >

        <Image src={mockup_pc2} fluid />
        </Col>
  </Row>
  <Row style={{justifyContent:'center',alignItems:'center', background: '#f5f7fa'}}>
        
        <Col xs={{ order: 'last' }} lg={{ order: 'last', span:6 }} style={{paddingInline:30, paddingBlock:30}}>
        
              <Col lg={{span: 10, offset: 1 }} xxl={{span: 7, offset: 2 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  className="text-start" style={{backgroundColor:''}}>
              <div class="masked"></div>
               </Col>
                <h1  style={{color:'black', fontSize:56,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("saas5")} <span className="gradient-text2">{t("saas6")}</span></h1>
                <h3  style={{color:'#4f6382', fontSize:25,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.4}}>{t("saas7")}</h3>
             
             
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  
    <Col style={{marginTop:0}}>
   
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/Devis'>{t("btn1")}</NavLink></Button>
   
          </Col>  
  </Row>
 
              </Col>
              {/* <Col  className="text-end" style={{backgroundColor:''}}>
               <Image src={doodle2} style={{ height:50}} />
               </Col> */}
               
        </Col>
        
        <Col xs={{ order: 'first' }} lg={{ order: 'first' , span:6}}  >

        <Image src={mockup_pc1} fluid />
        </Col>
  </Row>
  <Row style={{justifyContent:'center',alignItems:'center'}}>
        
        <Col xs={{ order: 'last' }} lg={{ order: 'first', span:6 }} style={{paddingInline:30, paddingBlock:30}}>
        
              <Col lg={{span: 10, offset: 1 }} xxl={{span: 7, offset: 2 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  className="text-start" style={{backgroundColor:''}}>
               <Image src={doodle1} style={{ marginLeft: width < 768 ? 0 : -50,height:50}} />
               </Col>
                <h1  style={{color:'black', fontSize:56,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><span className="gradient-text2">{t("saas8")}</span> {t("saas9")}</h1>
                <h3  style={{color:'#4f6382', fontSize:25,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.4}}>{t("saas10")}</h3>
             
             
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  
    <Col style={{marginTop:0}}>
   
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/Devis'>{t("btn1")}</NavLink></Button>
   
          </Col>  
  </Row>
 
              </Col>
              <Col  className="text-end" style={{backgroundColor:''}}>
               <Image src={doodle2} style={{ height:50}} />
               </Col>
               
        </Col>
        
        <Col xs={{ order: 'first' }} lg={{ order: 'last' , span:6}}  >

        <Image src={mockup_pc3} fluid />
        </Col>
  </Row>
</Container>
     </>
    )
}

export default Saas

